.my-event {
    background-color: green;
    border-radius: 5px;
    border: none;
    color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.parent-container-event-123 {
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: green;
    font-weight: bolder;
    border: none;
    padding: 5px;
}

.parent-container-event-123 .event-123 {
    background-color: green;
    border-radius: 5px;
    border: none;
    color: green;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.parent-container-event-12 {
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: white;
    font-weight: bolder;
    border: none;
    padding: 5px;
}

.parent-container-event-12 .event-12 {
    background-color: white;
    border-radius: 5px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4;
    color: white;
    width: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.not-marked {
    /* default styling for other events */
    background-color: green;
    border-radius: 5px;
    border: none;
    color: white;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.rbc-calendar {
    font-family: "YourFont", sans-serif; /* Change 'YourFont' to the desired font-family */
}

.rbc-label,
.rbc-event {
    font-family: "YourFont", sans-serif; /* Change 'YourFont' to the desired font-family */
}
