.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}
/* ------Grid searchbar bottom border---------  */
.grid-search
    .css-12fega5-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter
    .MuiInput-underline:before,
.grid-search .css-1igryyi-MuiInputBase-root-MuiInput-root:before,
.grid-search .css-1igryyi-MuiInputBase-root-MuiInput-root:before,
.grid-search
    .css-1igryyi-MuiInputBase-root-MuiInput-root:hover:not(
        .Mui-disabled
    ):before,
.grid-search .css-1igryyi-MuiInputBase-root-MuiInput-root:after {
    border-bottom: none;
}
.grid-header {
    background-color: "theme.palette.grey.200";
}
@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


@page {
    margin: 1cm;    
}


@media print{
    .descriptionContainer{
        margin-top: 190px;
    }  
    @page {
        size: A4; 
      }
     
    
}
